<script>
import playerMatchStatsComponent from './playerMatchStatsComponent.vue'
import { httpGet } from '../assets/js/utils.js'

export default {
    props: ['playerId', 'limit', 'team'],
    components: {
        playerMatchStatsComponent
    },
    watch: {
        limit: function() {
            this.getData()
        },
        playerId: function() {
            this.getData()
        }
    },
    data() {
        return {
            playerInfo: null,
            playerStats: null,
            isLoadingInfoPlayer: false,
            isLoadingPlayerStats: false
        };
    },
    methods: {
        async fetchPlayerInfoData() {
            return httpGet(`${process.env.VUE_APP_BACKEND_URL}/api/bestbets/playerInfo/${this.playerId}`);
        },
        async fetchPlayerStatsData() {
            return httpGet(`${process.env.VUE_APP_BACKEND_URL}/api/bestbets/playerStats/${this.playerId}?limit=${this.limit}`);
        },
        async getData() {
            try {
                this.isLoadingInfoPlayer = true;
                this.playerInfo = await this.fetchPlayerInfoData();
				// console.log(this.playerInfo);
                this.isLoadingInfoPlayer = false;

                this.isLoadingPlayerStats = true;
                this.playerStats = await this.fetchPlayerStatsData();
				// console.log(this.playerStats);
                this.isLoadingPlayerStats = false;
				// console.log(this.playerStats)
            } catch (error) {
                console.error('Error fetching player data:', error);
                this.isLoadingInfoPlayer = false;
                this.isLoadingPlayerStats = false;
            }
        }
    },
    created() {
        this.getData();
    }
};
</script>

<template>
    <div class="player-info-pop">
        <div class="player-info">
            <div class="player-info-header">
                <div class="player-name">
                    <h1>{{ playerInfo?.name }}</h1>
                    <p>{{ team }}</p>
                </div>
                <div class="player-demographics">
                    <div class="player-position">
                        <div class="position-circle">
                            <p>{{ playerInfo?.shortPosition }}</p>
                        </div>
                        <p>{{ playerInfo?.position }}</p>
                    </div>
                    <div>
                        <p>{{ playerInfo?.age }} years</p>
                    </div>
                </div>
            </div>
            <div class="player-stats" v-if="playerStats">
                <div class="greyheader">
                    <div class="stnd-name">
                        <p class="title">Averages from last 10 matches</p>
                    </div>
                </div>
                <div class="greyheader">
                    <div class="greyheader-value">
                        <p>Minutes</p>
                    </div>
                    <div class="greyheader-value" id="grey-shots">
                        <p>Shots</p>
                    </div>
                    <div class="greyheader-value" id="grey-ot">
                        <p>On Target</p>
                    </div>
                    <div class="greyheader-value" id="grey-assists">
                        <p>Assists</p>
                    </div>
                    <div class="greyheader-value" id="grey-goals">
                        <p>Goals</p>
                    </div>
                </div>
                <div class="flex">
                    <div class="avg-box">
                        <p>{{ playerStats?.averageStats?.minutes }}</p>
                    </div>
                    <div class="avg-box shots-value" v-bind:class="`player-shots-value-${playerStats?.averageStats?.shots * 10}`">
                        <p>{{ playerStats?.averageStats?.shots }}</p>
                    </div>
                    <div class="avg-box ot-shots-value" v-bind:class="`player-ot-shots-value-${playerStats?.averageStats?.onTarget * 10}`">
                        <p>{{ playerStats?.averageStats?.onTarget }}</p>
                    </div>
                    <div class="avg-box assist-value" v-bind:class="`player-assists-value-${playerStats?.averageStats?.assists * 10}`">
                        <p>{{ playerStats?.averageStats?.assists }}</p>
                    </div>
                    <div class="avg-box goals-value" v-bind:class="`player-goals-value-${playerStats?.averageStats?.goals * 10}`">
                        <p>{{ playerStats?.averageStats?.goals }}</p>
                    </div>
                </div>
            </div>
            <div class="player-match-list" v-if="playerStats">
                <div class="greyheader">
                    <div class="greyheader-player">
                        <p>Last 10 matches</p>
                    </div>
                    <div class="greyheader-value" id="grey-minutes">
                        <p>Min.</p>
                    </div>
                    <div class="greyheader-value" id="grey-shots">
                        <p>S</p>
                    </div>
                    <div class="greyheader-value" id="grey-target">
                        <p>OT</p>
                    </div>
                    <div class="greyheader-value" id="grey-assists">
                        <p>A</p>
                    </div>
                    <div class="greyheader-value" id="grey-goals">
                        <p>G</p>
                    </div>
                </div>
                <div class="player-match-list-items" v-if="!isLoadingPlayerStats">
                    <playerMatchStatsComponent :playerStats="playerStats?.fixtures"></playerMatchStatsComponent>
                </div>
				<div class="skeleton-player-match-list" v-if="isLoadingPlayerStats">
					<div class="player-match">
						<div class="match">
							<div class="match-info">
								<div class="match-result skeleton skeleton-player">
								</div>
							</div>
						</div>
						<div class="player-match-box minutes value skeleton">
						</div>
						<div class="player-match-box shots skeleton">
						</div>
						<div class="player-match-box ot skeleton">
						</div>
						<div class="player-match-box assist skeleton">
						</div>
						<div class="player-match-box goals skeleton">
						</div>
					</div>
					<div class="player-match">
						<div class="match">
							<div class="match-info">
								<div class="match-result skeleton skeleton-player">
								</div>
							</div>
						</div>
						<div class="player-match-box minutes value skeleton">
						</div>
						<div class="player-match-box shots skeleton">
						</div>
						<div class="player-match-box ot skeleton">
						</div>
						<div class="player-match-box assist skeleton">
						</div>
						<div class="player-match-box goals skeleton">
						</div>
					</div>
					<div class="player-match">
						<div class="match">
							<div class="match-info">
								<div class="match-result skeleton skeleton-player">
								</div>
							</div>
						</div>
						<div class="player-match-box minutes value skeleton">
						</div>
						<div class="player-match-box shots skeleton">
						</div>
						<div class="player-match-box ot skeleton">
						</div>
						<div class="player-match-box assist skeleton">
						</div>
						<div class="player-match-box goals skeleton">
						</div>
					</div>
					<div class="player-match">
						<div class="match">
							<div class="match-info">
								<div class="match-result skeleton skeleton-player">
								</div>
							</div>
						</div>
						<div class="player-match-box minutes value skeleton">
						</div>
						<div class="player-match-box shots skeleton">
						</div>
						<div class="player-match-box ot skeleton">
						</div>
						<div class="player-match-box assist skeleton">
						</div>
						<div class="player-match-box goals skeleton">
						</div>
					</div>	
					<div class="player-match">
						<div class="match">
							<div class="match-info">
								<div class="match-result skeleton skeleton-player">
								</div>
							</div>
						</div>
						<div class="player-match-box minutes value skeleton">
						</div>
						<div class="player-match-box shots skeleton">
						</div>
						<div class="player-match-box ot skeleton">
						</div>
						<div class="player-match-box assist skeleton">
						</div>
						<div class="player-match-box goals skeleton">
						</div>
					</div>	
					<div class="player-match">
						<div class="match">
							<div class="match-info">
								<div class="match-result skeleton skeleton-player">
								</div>
							</div>
						</div>
						<div class="player-match-box minutes value skeleton">
						</div>
						<div class="player-match-box shots skeleton">
						</div>
						<div class="player-match-box ot skeleton">
						</div>
						<div class="player-match-box assist skeleton">
						</div>
						<div class="player-match-box goals skeleton">
						</div>
					</div>
					<div class="player-match">
						<div class="match">
							<div class="match-info">
								<div class="match-result skeleton skeleton-player">
								</div>
							</div>
						</div>
						<div class="player-match-box minutes value skeleton">
						</div>
						<div class="player-match-box shots skeleton">
						</div>
						<div class="player-match-box ot skeleton">
						</div>
						<div class="player-match-box assist skeleton">
						</div>
						<div class="player-match-box goals skeleton">
						</div>
					</div>
					<div class="player-match">
						<div class="match">
							<div class="match-info">
								<div class="match-result skeleton skeleton-player">
								</div>
							</div>
						</div>
						<div class="player-match-box minutes value skeleton">
						</div>
						<div class="player-match-box shots skeleton">
						</div>
						<div class="player-match-box ot skeleton">
						</div>
						<div class="player-match-box assist skeleton">
						</div>
						<div class="player-match-box goals skeleton">
						</div>
					</div>	
					<div class="player-match">
						<div class="match">
							<div class="match-info">
								<div class="match-result skeleton skeleton-player">
								</div>
							</div>
						</div>
						<div class="player-match-box minutes value skeleton">
						</div>
						<div class="player-match-box shots skeleton">
						</div>
						<div class="player-match-box ot skeleton">
						</div>
						<div class="player-match-box assist skeleton">
						</div>
						<div class="player-match-box goals skeleton">
						</div>
					</div>	
					<div class="player-match">
						<div class="match">
							<div class="match-info">
								<div class="match-result skeleton skeleton-player">
								</div>
							</div>
						</div>
						<div class="player-match-box minutes value skeleton">
						</div>
						<div class="player-match-box shots skeleton">
						</div>
						<div class="player-match-box ot skeleton">
						</div>
						<div class="player-match-box assist skeleton">
						</div>
						<div class="player-match-box goals skeleton">
						</div>
					</div>
					<div class="player-match">
						<div class="match">
							<div class="match-info">
								<div class="match-result skeleton skeleton-player">
								</div>
							</div>
						</div>
						<div class="player-match-box minutes value skeleton">
						</div>
						<div class="player-match-box shots skeleton">
						</div>
						<div class="player-match-box ot skeleton">
						</div>
						<div class="player-match-box assist skeleton">
						</div>
						<div class="player-match-box goals skeleton">
						</div>
					</div>
				</div>
            </div>
        </div>
        <div class="close-bar">
            <div class="close-btn" @click="$emit('closePlayer')">
                <h3>Close Player Info</h3>
                <img src="./../assets/img/close-white.png" />
            </div>
        </div>
    </div>
</template>

<style scoped src="../assets/css/style.css">
    .crd-tab > div:not(.spinner) {
        display: none;
    }
    /* Show the spinner when isLoading is true */
    .crd-tab .spinner {
        display: block;
    }
</style>
